<template lang="pug">
.content(
  v-infinite-scroll="fetchData",
  infinite-scroll-disabled="isLaijiCoinlogBusy",
  :infinite-scroll-distance="scrollDistance",
  infinite-scroll-immediate-check="false"
)
  Content.app-content
    Row(span="24")
      Select.app-tool(
        v-model="provider",
        :placeholder="$t('Please select provider')",
        prefix="ios-flash",
        v-if="userInfo.isAdministrator || userInfo.isSuperOperator"
        @on-change="onProviderChange",
        filterable
      )
        Option(
          v-for="(option, index) in allProviderOptions",
          :value="option.value",
          :key="index"
        ) {{option.label}}

      Select.app-tool(
          v-model="operator",
          :placeholder="$t('Please select operator')",
          prefix="ios-flash",
          @on-change="onOperatorChange",
          v-if="userInfo.isAdministrator || userInfo.isSuperOperator"
          filterable
        )
          Option(v-for="(option, index) in operatorOptions", :value="option.value", :key="index") {{option.label}}

      Select.app-tool(
            v-model="action",
            prefix="md-swap",
            @on-change="onActionInputChange"
          )
            Option(v-for="(option, index) in actionOptions", :value="option.value", :key="index") {{option.label}}

      Select.app-tool(
          v-model="game"
          style="width:140px"
          prefix="ios-game-controller-a"
          @on-change="onGameInputChange"
          filterable
        )
          Option(v-for="item in allGamesOptions" :value="item.value" :key="item.value") {{ item.label }}

      DatePicker.app-tool.date(
        type="date"
        :options="dateOptions"
        :placeholder="$t('Select date')"
        v-model="date"
        @on-change="onDateChange"
      )

      TimePicker.app-tool.time(
        v-model="time"
        format="HH:mm"
        :placeholder="$t('Select time')"
        @on-change="onTimeChange"
      )

      Input.app-tool(
        v-model="search",
        icon="ios-search"
        clearable,
        :placeholder="$t('Search user name')",
        @on-change='onSearchInputChange'
        style="width: 120px;"
      )

      .sort-method(style="float: right;")
            span(style="margin-right: 8px;") {{ $t('Sort method') }}
            ButtonGroup
              Button(
                :type="getSortBtnType('asc')"
                @click="updateSort('asc')"
              ) {{ $t("Backward search") }}
              Button(
                :type="getSortBtnType('desc')"
                @click="updateSort('desc')"
              ) {{ $t("Forward search") }}
    //- 主表單內容
    Row(:style="{marginTop: '16px'}")
      Col(span='24', v-if="laijiCoinLogsList.length || !laijiCoinLogsListLoading")
        LaijiCoinLogsTable(
          :list-data="laijiCoinLogsList",
          :user-info="userInfo"
          @after-click-fullname="afterClickFullname"
        )

      Col(span='24', class="table-loading-col", v-if="laijiCoinLogsListLoading")
        Spin(fix)
          Icon(type="ios-loading", size=18 class="spin-icon")

</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import LaijiCoinLogsTable from '~v/laiji-coin-logs/laiji-coin-logs-table'
import moment from 'moment'
import Scroll from '~m/scroll'

export default {
  name: 'laiji-coin-logs',
  components: {
    LaijiCoinLogsTable
  },
  mixins: [Scroll],
  data () {
    return {
      provider: '',
      operator: '',
      game: '',
      date: moment().startOf('d').format('YYYY-MM-DD'),
      time: moment().startOf('d').format('HH:mm'),
      search: '',
      action: '',
      sort: 'desc',
      gameList: [],
      allProviderOptions: [],
      operatorOptions: [],
      defaultProviders: {
        value: 'all',
        label: this.$t('All providers')
      },
      defaultOperators: {
        value: 'all',
        label: this.$t('All operators')
      },
      defaultGames: {
        value: 'all',
        label: this.$t('All games')
      },
      dateOptions: {
        shortcuts: [
          {
            text: this.$t('Today'),
            value () {
              return moment().startOf('d').format('YYYY-MM-DD')
            }
          },
          {
            text: this.$t('Yesterday'),
            value () {
              return moment().add(-1, 'd').startOf('d').format('YYYY-MM-DD')
            }
          },
          {
            text: this.$t('Last week'),
            value () {
              return moment().add(-1, 'w').startOf('d').format('YYYY-MM-DD')
            }
          }
        ]
      },
      actionOptions: [
        {
          label: this.$t('Select laijiCoinlog action'),
          value: 'all'
        },
        {
          label: this.$t('LaijiCoin transfer in'),
          value: 'In'
        },
        {
          label: this.$t('LaijiCoin transfer out'),
          value: 'Out'
        }
      ]
    }
  },
  watch: {
    // 監聽網址的變化進行 data 重設
    async '$route' (nv, ov) {
      // pid 不一樣要更新 operator list
      this.resetSelector()
    },
    allProviders (nv, ov) {
      if (this.this.allProviderOptions.length) return
      this.allProviderOptions = [
        this.defaultProviders,
        ...nv
      ]
    },
    allOperators (nv, ov) {
      if (this.operatorOptions.length) return
      this.operatorOptions = [
        this.defaultOperators,
        ...nv
      ]
    },
    allGames (nv, ov) {
      if (this.allGames.length) return
      this.gameList = [
        this.defaultGames,
        ...this.allGames
      ]
    }
  },
  computed: {
    ...mapGetters('auth', ['userInfo']),
    // 嵌入營運商的 state 資料
    ...mapGetters('provider', { allProviders: 'getAllForSelector' }),
    ...mapGetters('operator', { allOperators: 'getAllForSelector' }),
    ...mapGetters('game', { allGames: 'getAllForSelector' }),
    ...mapGetters('laijiCoinLogs', {
      laijiCoinLogsList: 'getListData',
      laijiCoinLogsListLoading: 'getLoadingStatus',
      hasNextUser: 'hasNext'
    }),
    isLaijiCoinlogBusy () {
      return this.laijiCoinLogsListLoading || !this.hasNextUser
    }
  },
  methods: {
    ...mapActions('laijiCoinLogs', { findUserLaijiCoinLogs: 'find', resetUserLaijiCoinLogs: 'reset' }),

    updateRoute ({ path, query }) {
      this.$router.push({ path })
      this.$router.replace({ path, query })
    },

    // 新舊排序
    getSortBtnType (st) {
      return this.sort === st ? 'primary' : 'default'
    },

    updateSort (sort) {
      let { path, query } = this.$route
      query.sort = sort
      this.updateRoute({ path, query })
    },

    // provider input 用
    onProviderChange (val) {
      let { path, query } = this.$route
      query.provider = val
      this.updateRoute({ path, query })
    },

    // operator input 用
    onOperatorChange (val) {
      let { path, query } = this.$route
      query.operator = val
      this.updateRoute({ path, query })
    },

    // 交易類型
    onActionInputChange (val) {
      let { path, query } = this.$route
      query.action = val
      this.updateRoute({ path, query })
    },

    onGameInputChange (val) {
      let { path, query } = this.$route
      query.game = val
      this.updateRoute({ path, query })
    },

    onDateChange (val) {
      let { path, query } = this.$route
      query.date = val
      this.updateRoute({ path, query })
    },

    onTimeChange (val) {
      let { path, query } = this.$route
      query.time = val
      this.updateRoute({ path, query })
    },

    // search input 用
    onSearchInputChange (event) {
      clearTimeout(this.delaySearch)
      this.delaySearch = setTimeout(() => {
        let { path, query } = this.$route
        query.search = this.search
        this.updateRoute({ path, query })
      }, 1000)
    },

    // 向 API 取得 data
    // infinity scroll 需綁定
    fetchData (reset = false) {
      let { query } = this.$route
      let { search, operator, provider, action, sort, date, time, game } = query
      search = search === '' ? null : search
      if (provider === 'all') provider = null
      if (operator === 'all') operator = null
      if (action === 'all') action = null
      if (game === 'all') game = null
      sort = sort || this.sort
      date = date || this.date
      time = time || this.time

      if (reset) {
        this.resetUserLaijiCoinLogs({ search, operator, provider, action, sort, date, time, game })
      }
      this.findUserLaijiCoinLogs({ search, operator, provider, action, sort, date, time, game })
    },
    resetSelector () {
      let { search, operator, provider, action, sort, date, time, game } = this.$route.query
      this.operator = operator * 1 || 'all'
      this.provider = provider * 1 || 'all'
      this.game = game * 1 || 'all'
      this.search = search || ''
      this.sort = sort || 'desc'
      this.action = action || 'all'
      this.date = date || moment().startOf('day').format('YYYY-MM-DD')
      this.time = time || '00:00'
      this.fetchData(true)
    },
    afterClickFullname (username) {
      if (this.search !== username) {
        this.search = username
        this.onSearchInputChange()
      }
    }
  },
  async mounted () {
    this.resetSelector()
  }
}
</script>
